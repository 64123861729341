import React, { useState, useRef } from 'react';
import './ServiceSection.css'; // Import the CSS file
import Modal from './Modal'; // Import the Modal component

interface Service {
  name: string;
  price: string;
  description: string;
}

interface CategoryData {
  services: Service[];
  addons?: Service[]; // Only Cosmetology will have add-ons
}

interface ServiceCategories {
  [category: string]: CategoryData;
}

const serviceCategories: ServiceCategories = {
  'Manicure': {
    services: [
      { name: "Classic, European, Smart, Combined", price: "$40", description: "" },
      { name: "Gel remove, Manicure, Gel polish", price: "$75", description: "" },
      { name: "Gel remove, Manicure, Gel polish, Light Design", price: "$85", description: "" },
      { name: "Gel remove, Manicure, Gel polish, Extra Design", price: "$95", description: "" },
      { name: "Nail Care Naked", price: "$85", description: "" },
      { name: "P-Shine Japanese", price: "$100", description: "" },
      { name: "Manicure with Voesh three-stage ritual", price: "$75", description: "" },
    ]
  },
  'Pedicure': {
    services: [
      { name: "Classic, European, Smart, Combined", price: "$60", description: "" },
      { name: "Gel remove, Pedicure, Gel polish", price: "$85", description: "" },
      { name: "Gel remove, Pedicure, Gel polish, Light Design", price: "$95", description: "" },
      { name: "Gel remove, Pedicure, Gel polish, Extra Design", price: "$105", description: "" },
      { name: "Nail Care Naked", price: "$95", description: "" },
      { name: "P-Shine Japanese", price: "$115", description: "" },
      { name: "Jelly Spa Bath", price: "$90", description: "" },
      { name: "Pedicure with Voesh four-stage ritual", price: "$100", description: "" },
    ]
  },
  'Four hands service': {
    services: [
      { name: "Manicure & Pedicure Combo", price: "$130", description: "" },
      { name: "Manicure & Pedicure Combo with Gel Polish", price: "$175", description: "" }
    ]
  },
  'Sugaring': {
    services: [
      { name: "Lips", price: "$20", description: "" },
      { name: "Chin", price: "$20", description: "" },
      { name: "Lips & Chin", price: "$35", description: "" },
      { name: "Underarms", price: "$30", description: "" },
      { name: "Full Arms", price: "$50", description: "" },
      { name: "Half Arms", price: "$45", description: "" },
      { name: "Full Legs", price: "$85", description: "" },
      { name: "Half Legs", price: "$55", description: "" },
      { name: "Chest", price: "$40", description: "" },
      { name: "Stomach", price: "$15", description: "" },
      { name: "Back", price: "$75", description: "" },
      { name: "Bikini", price: "$55", description: "" },
      { name: "Brazilian", price: "$65", description: "" },
      { name: "Full Body", price: "$230", description: "" }
    ]
  },
  'Cosmetology': {
    services: [
      {
        name: "Collagen Renew 45 — 60 min.",
        price: "$130",
        description: "Lift & Sculpt facial — Instant lift & firming with powerful peptides for a youthful, sculpted look."
      },
      {
        name: "Regenerative Refresh 45 — 60 min.",
        price: "$130",
        description: "Line-Refining Facial — Smooth fine lines, boost elasticity & refresh skin with anti-aging peptides."
      },
      {
        name: "Spot Check 45 — 60 min.",
        price: "$110",
        description: "Complexion clarifying facial — Clear, refine & brighten with salicylic acid and antioxidants for a flawless glow."
      },
      {
        name: "HydroGlow 45 — 60 min.",
        price: "$120",
        description: "Skin brightening facial — Even skin tone & boost radiance with vitamin C and gentle exfoliation."
      },
      {
        name: "Moisture Infusion 45 — 60 min.",
        price: "$120",
        description: "Hydrating facial — Deeply hydrate, plump & restore glow with hyaluronic acid & peptides."
      },
      {
        name: "Calming Recovery 45 — 60 min.",
        price: "$130",
        description: "Sensitivity soothing facial — Soothe, reduce redness & strengthen skin with anti-inflammatory botanicals."
      },
      {
        name: "Facial with extraction 90+ min.",
        price: "$145+",
        description: ""
      },
      {
        name: "Peels 50 min",
        price: "$120",
        description: ""
      },
      {
        name: "LED therapy 45 min.",
        price: "$80",
        description: ""
      }
    ],
    addons: [
      {
        name: "LED therapy",
        price: "15 min. — $20 | 30 min. — $30",
        description: ""
      },
      {
        name: "Hydrojelly mask 20 min.",
        price: "$20",
        description: ""
      }
    ]
  }
};

const ServiceSection = () => {
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const contentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleToggle = (category: string) => {
    setExpandedCategory(prev => (prev === category ? null : category));
  };

  const handleModalOpen = (content: JSX.Element) => {
    setModalContent(content);
  };

  const handleModalClose = () => {
    setModalContent(null);
  };

  const isDesktop = window.innerWidth >= 1024;
  const categories = Object.keys(serviceCategories);

  // Render the category content, and if the category is Cosmetology, explicitly display the add-ons.
  const renderCategoryContent = (category: string) => {
    const data = serviceCategories[category];
    return (
      <div className="scrollable-content">
        {data.services.map((service, index) => (
          <div key={index} className="border-t border-green-dark pt-2 px-4 pb-4">
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold">{service.name}</h4>
              <p className="text-lg font-bold font-avenirservice">{service.price}</p>
            </div>
            {service.description && (
              <p className="text-sm text-gray-600 mt-1">{service.description}</p>
            )}
          </div>
        ))}
        {category === 'Cosmetology' && data.addons && data.addons.length > 0 && (
          <>
            <h4 className="text-xl font-semibold mt-4 px-4">Add Ons:</h4>
            {data.addons.map((addon, index) => (
              <div key={index} className="border-t border-green-dark pt-2 px-4 pb-4">
                <div className="flex justify-between">
                  <h4 className="text-lg font-semibold">{addon.name}</h4>
                  <p className="text-lg font-bold font-avenirservice">{addon.price}</p>
                </div>
                {addon.description && (
                  <p className="text-sm text-gray-600 mt-1">{addon.description}</p>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <section id="services" className="bg-beige-100 py-0 my-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {categories.map((category) => (
            <div key={category} className="group">
              <div
                className="service-button bg-green-dark text-white p-4 rounded-lg shadow cursor-pointer overflow-hidden transition-all duration-500 ease flex items-center justify-center"
                onClick={() =>
                  isDesktop
                    ? handleModalOpen(renderCategoryContent(category))
                    : handleToggle(category)
                }
                style={{ minHeight: '100px' }}
              >
                <h3 className="text-2xl md:text-3xl text-center font-semibold font-avenir">
                  {category}
                </h3>
              </div>
              {!isDesktop && expandedCategory === category && (
                <div
                  ref={el => (contentRefs.current[category] = el)}
                  className="transition-all duration-500 ease-in-out overflow-hidden bg-white slide-down"
                >
                  {renderCategoryContent(category)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={!!modalContent} onClose={handleModalClose} title="Service Details">
        {modalContent}
      </Modal>
    </section>
  );
};

export default ServiceSection;
